// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-template-js": () => import("/opt/build/repo/src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beneficios-js": () => import("/opt/build/repo/src/pages/beneficios.js" /* webpackChunkName: "component---src-pages-beneficios-js" */),
  "component---src-pages-contacto-js": () => import("/opt/build/repo/src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-directivos-js": () => import("/opt/build/repo/src/pages/directivos.js" /* webpackChunkName: "component---src-pages-directivos-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noticias-js": () => import("/opt/build/repo/src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-socios-js": () => import("/opt/build/repo/src/pages/socios.js" /* webpackChunkName: "component---src-pages-socios-js" */)
}

